import { useEffect, useState } from 'react';
import { getHash, measureExecutionTime } from '~/utils';
import { globalStore } from '~/store';
import { FONT_LIST } from '~/utils/fonts';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

const initialFonts: string[] = FONT_LIST;

const detectFonts = (fonts: string[]): Promise<string[]> => {
  return new Promise((resolve) => {
    const detectedFonts: string[] = [];
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      resolve([]);
      return;
    }

    context.font = '72px monospace';
    const defaultWidthReference = context.measureText('mmmmmmmmmmlli').width;

    fonts.forEach((font) => {
      context.font = `72px ${font}, monospace`;
      const width = context.measureText('mmmmmmmmmmlli').width;
      if (width !== defaultWidthReference) {
        detectedFonts.push(font);
      }
    });

    resolve(detectedFonts);
  });
};

const FontList: React.FC = () => {
  const [availableFonts, setAvailableFonts] = useState<{ hash: string; data: { fonts: string[]; count: number } } | null>(null);

  useEffect(() => {
    const loadFonts = async () => {
      const { result: detectedFonts, time: execTime } = await measureExecutionTime(async () => await detectFonts(initialFonts));

      const hash = await getHash(detectedFonts);
      const data = { count: detectedFonts.length, fonts: detectedFonts };

      const fontStoreData = { hash, data };

      setAvailableFonts(fontStoreData);

      globalStore.set({ ...globalStore.get(), fonts: fontStoreData });
    };

    loadFonts();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Fonts
        <span className="text-sm border px-1">{availableFonts?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={availableFonts} />
      </div>
    </div>
  );
};

export default FontList;
